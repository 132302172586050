import "core-js/stable";
import "regenerator-runtime/runtime";

function connectForm(formName, callback) {
  $(`form[name="${formName}"]`).validate({
    rules: {
      user_name: "required",
      user_company: "required",
      user_email: {
        required: true,
        email: true
      },
    },
    messages: {
      user_name: "Please enter your name",
      user_company: "Please enter your company name",
      user_email: "Please enter a valid email address"
    },
    submitHandler: function (form) {
      const url = $(form).attr('action');
      const message = {};
      $(form).find('.input').each(function () {
        message[this.name] = this.value;
      });
      $('.loading-dots').addClass('loading-dots--loading');
      fetch(url, {
        method: $(form).attr('method'),
        headers: {
          'Accept': 'application/json',
          ['Content-Type']: 'application/json',
          ['X-CSRF-Token']: $(form).find('[name="authenticity_token"]').val(),
        },
        body: JSON.stringify({
          message
        })
      }).then(res => {
        if (res.ok) {
          res.json();
        }
      }).then(res => {
        if (!!callback) {
          callback(res);
        }
        $('.loading-dots').removeClass('loading-dots--loading');
        $('.contact-form').addClass('contact-form--submited');

        setTimeout(() => {
          $(form).find('.input').each(function () {
            this.value = '';
          });
          $('.contact-form').removeClass('contact-form--submited');
        }, 5000)
      });
    }
  });
}

window.recaptchaOnSuccess = function () {
  document.getElementById("submit-btn").disabled = false;
};

window.recaptchaOnError = function () {
  document.getElementById("submit-btn").disabled = true;
}

function onModalFormSubmit() {
  $('#contact-us-modal').modal('hide');
}

$(document).ready(function () {
  connectForm('contact-form');
  connectForm('contact-modal', onModalFormSubmit);
});
